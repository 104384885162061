// import Rectangle84 from "../../assets/img/Rectangle84.png";
// import profileImage from '../../assets/img/profile-image.jpg';
// import Rectangle89 from "../../assets/img/Rectangle91.png";
// import icon from "../../assets/img/icon.png";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Link from 'next/link';
import { useTranslation } from "next-i18next";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FacebookShareButton } from "react-share";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import hooks from "./commentsHooks";
import styles from './Comments.module.scss';
import moment from 'moment';
import { master_address_select, master_schedule } from '../../store/actions/master';


function AvatarForPost(props) { 
    const {
        anchorEl,
        open,
        open_report,
        classes,
        modalStyle,
        report_text,
        router,
        translate,
        // goToRegisterMaster,
        dispatch,
        setAnchorEl,
        setOpen,
        setOpenReport,
        handleClick,
        handleClose,
        setReportText,
    } = hooks(props);
    const { t, i18n } = useTranslation();
    const success_notify = () => toast.success(t("successfully_copied"), {
        position: toast.POSITION.TOP_CENTER
    });
    
    const body = (
        <div style={modalStyle} className={classes.paper}>
            <p id="simple-modal-description" className="modal-title">
                {t("want_hide")}
            </p>
            <div className='modal-line'></div>
            <div className='d-flex justify-content-evenly'>
                <span className='button-title'
                    onClick={() => setOpen(false)}>{t("no")}</span>
                <span className='button-title'
                    onClick={() => {
                        props.hide_post();
                        setOpen(false);
                    }}>{t("yes")}</span>
            </div>
        </div>
    );
    const body_report = (
        <div style={modalStyle} className={classes.paper}>
            <p id="simple-modal-description" className="modal-title">
                {t("would_like_complain")}
            </p>
            <div className='modal-line'></div>
            <div className='d-flex justify-content-evenly'>
                <span className='button-title'
                    onClick={() => setOpenReport(false)}>
                    {t("no")}
                </span>
                <span className='button-title'
                    onClick={() => {
                        // props.report_post();
                        setOpenReport("writeTest");
                    }}>{t("yes")}</span>
            </div>
        </div>
    );
    const text_report = (
        <div style={modalStyle} className={classes.paper}>
            <p id="simple-modal-description"
                className="modal-title">
                {t("cause")}
            </p>
            <input className={styles.report_input}
                autoFocus={true}
                value={report_text}
                onChange={(e) => {
                    let value = e.target.value;
                    setReportText(value);
                }} />
            <div className='modal-line'></div>
            <div className='d-flex justify-content-evenly'>
                <span className='button-title'
                    onClick={() => {
                        props.report_post(report_text);
                        setOpenReport(false);
                        setReportText("");
                    }}>{t("send")}</span>
            </div>
        </div>
    );

    const d = new Date(props?.free_day);
    const day_with_format = moment(d).format('DD MMMM');
    const weekday = moment(d).format('ddd');
    const handleChange = (data) => {
        dispatch(master_address_select({ selected_service: data?.selected_service }));
        dispatch(master_schedule({
            calendar_value: {
                day: d.getDate(),
                month: d.getMonth() + 1,
                year: d.getFullYear()
            }
        }))
        router.push(`/master/${props?.id}/register-master/?free_day=${props?.free_day}&service=${data?.selected_service}`)
    };
    return (
        <div className="d-flex">
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
            <Modal
                open={open_report}
                onClose={() => setOpenReport(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body_report}
            </Modal>
            <Modal
                open={open_report === "writeTest"}
                onClose={() => setOpenReport(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {text_report}
            </Modal>
            <Link as={`/${props.language}/master/${props.id}`}
                href={`/${props.language}/master/${props.id}`}>
                <a><img className={styles.prf_image} src={props.image
                    ? props.image
                    : "/profile-user.svg"} /></a>
            </Link>
            <div className="post-category d-flex flex-column comment-name w-100 justify-content-center">
                <div className="d-flex justify-content-between position-relative w-100">
                    <div className='w-100 d-flex flex-column'>
                        <Link as={`/${props.language}/master/${props.id}`} href={`/${props.language}/master/${props.id}`}>
                            {props.first_name && props.last_name
                                ? <a className={styles.name}>
                                    {props.first_name} {props.last_name}
                                </a>
                                : <a className={styles.name}>{props.username}</a>
                            }
                        </Link>
                        <div className='w-100 d-none d-md-flex flex-column'>
                            <div className='d-md-flex justify-content-between align-items-center'>
                                <div className={`${styles.service_name_post} ${styles[props.with_ellipses]}`}>{props?.service} <div className={styles.service_price_post}>{props.price} €</div></div>
                                {props?.free_day && <div className={styles.free_day}>
                                    <span className={styles.label}>{t('bookFor')}</span>
                                    {props?.free_day && <span
                                        className={styles.next_date_post}
                                        onClick={() => handleChange({
                                            selected_service: props?.service_id,
                                            free_day: props?.free_day
                                        })}
                                    >
                                        {weekday} {day_with_format}
                                    </span>}
                                </div>}
                            </div>


                        </div>
                        <div className='w-100 d-md-none justify-content-between align-items-center'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className={`${styles.service_name_post} ${styles[props.with_ellipses]}`}>{props?.service} <span className={styles.service_price_post}>{props.price}€</span></div>
                                <div className={styles.service_price_post}></div>
                            </div>
                            {props?.free_day && <div className={`${styles.free_day} w-100 d-flex justify-content-between`}>
                                <span className={styles.label}>{t('bookFor')}</span>
                                {props?.free_day && <span className={styles.next_date_post}
                                    onClick={() => handleChange({
                                        selected_service: props?.service_id,
                                        free_day: props?.free_day
                                    })}
                                >
                                    {weekday} {day_with_format}
                                </span>}
                            </div>}
                        </div>

                    </div>
                    {!props.hideOptions && <Button aria-controls="simple-menu"
                        className='avatarOption'
                        aria-haspopup="true"
                        onClick={handleClick}>
                        <div className={styles.see_more}>...</div>
                    </Button>}
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => setOpenReport(true)}>
                            {t("complain")}
                        </MenuItem>
                        <CopyToClipboard text={props.url}>
                            <MenuItem
                                onClick={handleClose, success_notify}
                            >
                                {t("copy_link")}
                            </MenuItem>
                        </CopyToClipboard>
                        <MenuItem onClick={() => {
                            router.push(`/${i18n.language}/${props.post_id}`)
                            // props.hide_post()
                        }}>{t("go_to_link")}</MenuItem>
                        <FacebookShareButton url={props.url}>
                            <MenuItem onClick={handleClose}>
                                {t("share_in")}
                            </MenuItem>
                        </FacebookShareButton>
                        <MenuItem onClick={() => {
                            setAnchorEl(null);
                            setOpen(true);
                            // props.hide_post()
                        }}>{t("hide")}</MenuItem>
                    </Menu>
                </div>
                {props?.zip_code || props?.city
                ? <div className={`${styles.zip_code} zip-code`}>{props?.zip_code} {props?.city}</div>
                :<div className={`${styles.zip_code} zip-code`}>{props?.address}</div>}
                {/* <div className={styles.descrition}>{props.profession}</div> */}
            </div>
        </div>
    );
};
export default AvatarForPost;