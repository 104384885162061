import React from 'react';
import Link from "next/link";
import styles from "./MasterSliderImages.module.scss";
function MasterSliderImages(props) {

    return (
        <Link as={props.url} href={props.url} >
            <a className={styles.slide_images}>
                <div className={styles.slide_images}>
                    <figure className={styles.image_container}>
                        <img src={props.src} className="w-100 h-100 position-absolute" alt="" />
                    </figure>
                    <div className={styles.text}>{props.text}</div>
                </div>
            </a>
        </Link>
    );
}
export default MasterSliderImages;
