import React, { useState, useMemo, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Heart from "react-animated-heart";
import { Player } from 'video-react';
import ClipLoader from "react-spinners/ClipLoader";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import FsLightbox from 'fslightbox-react';
import Lightbox from 'react-image-lightbox';
import { useTranslation } from "next-i18next";
import styles from './Comments.module.scss';
import Link from "next/link";
import hooks from "./commentsHooks";
import translate_word from "../../utils/translate";
import 'react-image-lightbox/style.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import AvatarForPost from './AvatarForPost';
import PostCommentSlider from '../PostCommenSlider';
import useLongPress from '../../hooks/useLongPress';
const DynamicCommentItem = dynamic(() => import('./CommentItem'));
const DynamicCommentsData = dynamic(() => import('./CommentsData'));
const DynamicAvatar = dynamic(() => import('./Avatar'));
const DynamicCommentsInput = dynamic(() => import('./CommentsInput'));
var canUseDOM = !!(
    (typeof window !== 'undefined' &&
        window.document && window.document.createElement)
);
function Comments(props) {
    const [toggler, setToggler] = useState(false);
    const [productIndex, setProductIndex] = useState(1);
    const { comments, seeMore, setSeeMore, posts_like_dislike, isClick, like_dislike, isClinet, likeCount, translate } = hooks(props);
    const url = canUseDOM ? `${window.location.href}${props.item.id}` : "";
    const [sliderCurrentId, setSliderCurrentId] = useState(0);
    const images = useMemo(() => {
        return props.item?.files?.map((el) => el?.file_url);
    }, [props.item.files]);
    const onLongPress = () => {
        // if (props.token) {
        posts_like_dislike()
        // }
    };
    const longPressEvent = useLongPress(onLongPress);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        beforeChange: (current, next) => {
            if (next < current) {
                setSliderCurrentId(sliderCurrentId - 1);
            } else if (next !== current) {
                setSliderCurrentId(sliderCurrentId + 1);
            }
        },
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: false,
                }
            }
        ]
    };
    const hash_list = props.item.text.split(" ");
    const { t, i18n } = useTranslation();
    
    return (
        <div className={styles.comments_cont}>
            <div className="w-100 d-flex flex-column flex-md-column justify-content-between">
                <div className="post-main-avatar">
                    <div className={styles.avatar_container}>
                        <AvatarForPost
                            language={props.language}
                            id={props.item.user_details.id}
                            profession={translate_word(props.item.service_details, i18n.language)}
                            hide_post={() => props.hide_post()}
                            report_post={(text) => props.report_post(text)}
                            url={url}
                            username={props.item.user_details.username}
                            first_name={props.item.user_details.first_name}
                            last_name={props.item.user_details.last_name}
                            image={props.item.user_details.image}
                            price={props?.item?.price}
                            free_day={props?.item?.free_day}
                            service={translate_word(props.item.service_details, i18n.language)}
                            service_id={props.item.service_details.id}
                            post_id={props.item.id}
                            zip_code={props?.item?.zip_code}
                            address={props?.item?.address?.substr(0, props?.item?.address?.lastIndexOf(","))}
                            city={props?.item?.city}

                        />
                    </div>
                </div>
                <div className={styles.comments_images}>
                    <div className="posts-page-slider"
                        {...longPressEvent}
                    >
                        {isClick && <div className="animated_heart">
                            <img src='/output-onlinegiftools.gif' />
                        </div>}
                        <div className={styles.img_count}>
                            {sliderCurrentId + 1} / {props.item.files.length}
                        </div>
                        <Slider {...settings}>
                            {props.item.files.map((item, index) => {
                                let myImg = document?.querySelector(`#webimg${index}`);
                                let realWidth = myImg?.naturalWidth;
                                let realHeight = myImg?.naturalHeight;
                                let windowWidth = window.innerWidth;
                                return index < 3 ?
                                    <div style={{
                                        width: '100%',
                                        height: realHeight
                                    }}>
                                        <div key={index}
                                            className={item.file_type === "image"
                                                ? styles.gallery_image_link
                                                : styles.gallery_video_link}>
                                            {/* {props.item.files.length > 3 && index === 0
                                                ? <div className={styles.more_images}>+{props.item.files.length - 3}</div>
                                                : null} */}
                                            {item.file_type === "image"
                                                ? <LazyLoadImage
                                                    width={'100%'}
                                                    // height={realHeight}
                                                    id={`webimg${index}`}
                                                    className={styles.gallery_image}
                                                    effect="blur"
                                                    alt="image"
                                                    src={item.file_url}
                                                    onClick={() => {
                                                        setProductIndex(index);
                                                        setToggler(!toggler);
                                                    }}
                                                />
                                                : <div className={index !== 0
                                                    ? styles.gallery_video
                                                    : "main-post"}
                                                    onClick={() => {
                                                        setProductIndex(index + 1);
                                                        setToggler(!toggler);
                                                    }}>
                                                    <video controls muted autoPlay className={styles.post_frist_image}>
                                                        <source src={item.file_url} type="video/mp4" />
                                                    </video>
                                                </div>}
                                        </div>
                                    </div>
                                    : null;
                            })}
                        </Slider>
                        <PostCommentSlider
                            order_count={props?.item?.order_count}
                            array={props.comments}
                        />
                    </div>
                    {toggler && <Lightbox
                        mainSrc={images[productIndex]}
                        nextSrc={images[(productIndex + 1) % images.length]}
                        prevSrc={images[(productIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setToggler(false)}
                        onMovePrevRequest={() => setProductIndex((productIndex + images.length - 1) % images.length)}
                        onMoveNextRequest={() => setProductIndex((productIndex + images.length + 1) % images.length)
                        }
                    />}
                </div>
                <div className={styles.comment_data}>
                    <div className="comment-slider"
                        {...longPressEvent}
                    >
                        {isClick && <div className="animated_heart">
                            <img src='/output-onlinegiftools.gif' />
                        </div>}
                        {isClinet && <Slider {...settings}>
                            {props.item.files.map((item, index) => {
                                let myImg = document?.querySelector(`#img${index}`);
                                let realWidth = myImg?.naturalWidth;
                                let realHeight = myImg?.naturalHeight;
                                let windowWidth = window.innerWidth;
                                return item.file_type === "image"
                                    ? <div
                                        className={styles.mobile_work_slider} key={index}
                                        style={{
                                            width: '100%',
                                            height: realWidth > windowWidth && windowWidth < 480 && realWidth > realHeight ? windowWidth : '100%'
                                        }}>
                                        <LazyLoadImage
                                            id={`img${index}`}
                                            src={item.file_url}
                                            effect="blur"
                                            width={realWidth < windowWidth && windowWidth < 480 && props.item.files.length === 1 ? realWidth : '100%'}
                                            height={realWidth < windowWidth && windowWidth < 480
                                                ? realHeight
                                                : props.item.files.length > 1 && windowWidth < 480 ? windowWidth : '100%'}
                                            className={styles.comments_slide_images}
                                        />
                                        <div className="d-flex z-10 justify-content-center align-items-center">
                                            {index + 1}/{props.item.files.length}</div>
                                    </div>
                                    : <div className={index !== 0 ? "post-video" : "main-post"} key={index}>
                                        <video controls muted className={styles.post_frist_image}>
                                            <source src={`${item.file_url}#t=0.001`} />
                                        </video>
                                    </div>;
                            })}
                        </Slider>}
                        <PostCommentSlider
                            order_count={props?.item?.order_count}
                            array={props.comments}
                        />
                    </div>
                    <DynamicCommentsData
                        onClick={() => {
                            posts_like_dislike(props.item.like_count)
                        }}
                        like_dislike={like_dislike}
                        token={props.token}
                        id={props.item.id}
                        liked_by_me={props.item.liked_by_me}
                        like_count={likeCount ? likeCount : props.item.like_count}
                        comment_count={props.item.comment_count}
                        seen_count={props.item.seen_count}
                        language={props.language}
                    />
                    {props.item.text && <div className=''>
                        <Link as={`/${props.language}/master/${props.item.user_details.id}`} href={`/${props.language}/master/${props.item.user_details.id}`}>
                            <a className={`${styles.text} ${styles.text_name}`}>{props.item.user_details.first_name} {props.item.user_details.last_name}</a>
                        </Link>
                        <Link as={`/${props.language}/${props.item.id}`} href={`/${props.language}/${props.item.id}`}>
                            <a><div className={styles.text}>{hash_list.map((char, charInd) => <span
                                className={char.charAt(0) == "#" ? styles.hash_tag_item : styles.comments_text_item}
                                key={charInd}>{char}</span>)}
                            </div>
                            </a>
                        </Link></div>}
                    {props.item.hash_tag_posts.length > 0 && <div className={styles.hash_tag_list}>
                        {props.item.hash_tag_posts.map((item, index) => {
                            return <Link as={`/${props.language}?hash_tag=${item.hash_tag_details.text}`}
                                href={`/${props.language}?hash_tag=${item.hash_tag_details.text}`} key={index}>
                                <a onClick={() => {
                                    var hash_tag = item.hash_tag_details.text;
                                    props.get_post(hash_tag);
                                }} key={index}>
                                    <div className={styles.hash_tag_item} key={index}>#{item.hash_tag_details.text}</div>
                                </a></Link>;
                        })}
                    </div>}
                    {comments[props.item.id] && !comments[props.item.id].loading ?
                        <DynamicCommentItem
                            my_image={props.my_image}
                            get_value={(arg) => props.get_value(arg)}
                            onChange={(value) => props.onChange(value)}
                            token={props.token}
                            onClick={(id) => { props.onClick(id); }}
                            get_comments={() => props.get_comments()}
                            get_comments_more={() => props.get_comments_more()}
                            item={comments[props.item.id]}
                            id={props.item.id}
                            value={comments[props.item.id].new_comment_value}
                        /> : null}
                    {/* {!seeMore && props.item.comment_count > 0 ?
                        <p className={styles.see_all_comments} onClick={() => {
                            setSeeMore(!seeMore);
                            props.get_comments();
                        }}>{t("viewAllCommentsLabel")}</p> : null}
                    {
                    }
                    {comments[props.item.id] && comments[props.item.id].loading ?
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <ClipLoader color="#464646" size={15} />
                        </div> : null}
                    {props.token !== "" && <DynamicCommentsInput
                        placeholder={t("addCommentLabel")}
                        my_image={props.my_image}
                        readOnly={comments.disabled}
                        id={props.item.id}
                        value={props.item.new_comment_value}
                        onChange={(value) => props.onChangePostComment(value)}
                        onClick={() => props.propsClickPostComment()}
                    />} */}
                </div>
            </div>
        </div>
    );
}
export default Comments;