import { Posts } from "../constants";
export const posts_delete = () => ({
    type: Posts.POSTS_DELETE
})
export const get_posts_slider = (data) => ({
    type: Posts.GET_POSTS_SLIDER,
    payload: data
})
export const set_posts_slider = (data) => ({
    type: Posts.SET_POSTS_SLIDER,
    payload: data
})
export const get_help = () => ({
    type: Posts.GET_HELP
})
export const set_help = (data) => ({
    type: Posts.SET_HELP,
    payload: data
})
export const new_posts_array = (data) => ({
    type: Posts.NEW_POSTS_ARRAY,
    payload: data
})
export const posts_loading = () => ({
    type: Posts.POSTS_LOADING,
})
export const posts_array = (data) => ({
    type: Posts.POSTS_ARRAY,
    payload: data
})
export const posts_array_saga = (data) => ({
    type: Posts.POSTS_ARRAY_SAGA,
    payload: data
})
export const posts_hash_tag = (data) => ({
    type: Posts.POSTS_ARRAY_HASH_TAG,
    payload: data
})
export const posts_comment_loading = (data) => ({
    type: Posts.POSTS_COMMENT_LOADING,
    payload: data
})
export const posts_comment_array = (data) => {
    return {
        type: Posts.POSTS_COMMENTS,
        payload: data
    }
}
export const posts_comment_array_saga = (data) => {
    return {
        type: Posts.POSTS_COMMENT_SAGA,
        payload: data
    }
}
export const hide_post_saga = (data) => ({
    type: Posts.HIDE_POST_SAGA,
    payload: data
})
export const report_post_saga = (data) => ({
    type: Posts.POST_REPORT_SAGA,
    payload: data
})
export const like_dislike = (data) => ({
    type: Posts.LIKE_OR_DISLIKE,
    payload: data
})
export const like_dislike_saga = (data) => ({
    type: Posts.LIKE_OR_DISLIKE_SAGA,
    payload: data
})
export const add_comment_act = (data) => ({
    type: Posts.ADD_COMMENT,
    payload: data
})
export const add_post_page_comment = (data) => ({
    type: Posts.ADD_POST_PAGE_COMMENT,
    payload: data
})
export const add_comment_saga = (data) => ({
    type: Posts.ADD_COMMENT_SAGA,
    payload: data
})
export const post_data = (data) => ({
    type: Posts.POST_DATA,
    payload: data
})
export const post_data_saga = (data) => ({
    type: Posts.POST_DATA_SAGA,
    payload: data
})
export const set_comment_value = (data) => ({
    type: Posts.NEW_COMMENT_VALUE,
    payload: data
})
export const set_post_comment_value = (data) => {
    return ({
        type: Posts.ADD_POSTS_COMMENTS,
        payload: data
    })
}
export const add_comment_loading = () => ({
    type: Posts.ADD_COMMENT_LOADING,
})
export const post_page_comment_loading = () => ({
    type: Posts.POST_PAGE_COMMENTS_LOADING,
})
export const post_page_comment = (data) => ({
    type: Posts.POST_PAGE_COMMENTS,
    payload: data
})
export const post_page_comment_input_value = (data) => ({
    type: Posts.POST_PAGE_COMMENT_INPUT_VALUE,
    payload: data
})
export const post_page_child_comment_input_value = (data) => ({
    type: Posts.POST_PAGE_CHILD_COMMENT_INPUT_VALUE,
    payload: data
})
export const popular_master_saga = (data) => ({
    type: Posts.POPULAR_MASTER_SAGA,
    payload: data
})
export const popular_master = (data) => ({
    type: Posts.POPULAR_MASTER,
    payload: data
})
export const delete_post_data = () => ({
    type: Posts.DELETE_POST_DATA,
})
export const add_view = (data) => {
    return ({
        type: Posts.ADD_VIEW,
        payload: data
    })
}

