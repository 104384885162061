import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from "next/router";
import {
    posts_array_saga,
    posts_loading,
    set_post_comment_value,
    add_comment_saga, add_comment_loading,
    set_comment_value,
    add_comment_act,
    posts_comment_array,
    posts_comment_array_saga,
    posts_comment_loading,
    hide_post_saga,
    report_post_saga,
    posts_delete,
    get_posts_slider
} from "../store/actions/posts";
import getPosition from "../utils/getDistance";
const queryString = require('query-string');

const HomeHooks = (props) => {
    const [mybutton, setMybutton] = useState();
    const posts = useSelector(state => state.posts);
    const comments = useSelector(state => state.comments);
    const popular_master = useSelector(state => state.popularMaster);
    const posts_slider = useSelector(state => state.postsSlider); 
    const user = useSelector(state => state.getme.data);
    const translate = useSelector(state => state.translate);
    const languages = useSelector(state => state.language);
    const router = useRouter();
    const dispatch = useDispatch();
    const set_post_comment = (data) => dispatch(set_post_comment_value(data));
    const set_child_comment = (data) => dispatch(set_comment_value(data));
    const get_new_post = (props) => {
        const { token, ...rest } = props;
        const params = queryString.parse(location.search);
        if (!rest.page) {
            delete params.page;
            delete params.offset;
        }
        // const stringified = queryString.stringify({ ...params, ...rest });
        // var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + stringified;
        // window.history.pushState({ path: newurl }, '', newurl);
        return dispatch(
            posts_array_saga({
                data: {
                    ...params,
                    ...rest
                }, token: token
            }));
    };
    const canUseDOM = !!(
        (typeof window !== 'undefined' &&
            window.document && window.document.createElement)
    );
    const add_child_comment = (data) => {
        return dispatch(
            add_comment_saga({
                loading: add_comment_loading,
                cb: add_comment_act,
                lat: props.lat,
                lng: props.lng,
                query: props.query,
                token: data.token,
                comment: comments[data.post_id].new_comment_value,
                post: data.post_id,
                user: user.id,
                parent: data.comment_id
            }),
        );
    };
    const add_comment = (data) => dispatch(
        add_comment_saga({
            cb: add_comment_act,
            query: props.query,
            token: data.token,
            comment: data.comment,
            post: data.post_id,
            user: user.id,
        }),
    );
    const hide_post = (data) => dispatch(
        hide_post_saga({
            ...data,
            radius: 100000,
            data: {
                lat: props.lat,
                limit: 10,
                long: props.lng,
                offset: 0,
                hide_hiddens: true
            }
        }),
    );
    const report_post = (data) => {
        dispatch(
            report_post_saga({
                ...data,
                radius: 100000,
                data: {
                    lat: props.lat,
                    long: props.lng,
                    limit: 10,
                    offset: 0,
                    hide_hiddens: true
                }
            })
        );
    };
    const posts_loader = () => dispatch(posts_loading());
    const get_comments = (data) => dispatch(
        posts_comment_array_saga({
            act_data: data,
            loading: posts_comment_loading,
            cb: posts_comment_array,
            token: data.token,
            offset: (comments[data.post_id] && 10 < comments[data.post_id].results.length < comments[data.post_id].count)
                ? ((comments[data.post_id].results.length / 10) * 10) : "", post: data.post_id
        }));
    const infiniteScroll = () => {
        get_new_post(props.lat && props.lng ? {
            ...props.query,
            token: props.token,
            lat: +props.lat,
            long: +props.lng,
            page: posts.results.length / 10 + 1,
            limit: 10,
            only_post: true,
            hide_hiddens: true
        } : {
            ...props.query,
            token: props.token,
            page: posts.results.length / 10 + 1,
            limit: 10,
            only_post: true,
            hide_hiddens: true
        });
    };
    const posts_delete_act = (data) => {
        dispatch(
            posts_delete(data)
        );
    };

    useEffect(() => {
        // if (lat) {
        // radius = await category.radius({ ...query, lat, long: lng, limit: 10, hide_hiddens: true });
        // posts = await category.posts({ data: { ...query, lat, long: lng, limit: 10, page: 1, hide_hiddens: true }, radius: radius?radius?.radius:'' });
        // popularMaster = await category.popular_master({ ...query, lat: +lat, long: +lng, page: 1, limit: 10, radius: radius?radius?.radius:'' });
        // } else {
        // radius = await category.radius({ ...query, limit: 10, hide_hiddens: true });
        // posts = await category.posts({ data: { ...query, limit: 10, page: 1, hide_hiddens: true }, radius: radius?radius?.radius:'' });
        // popularMaster = await category.popular_master({ ...query, page: 1, limit: 10, radius: radius?radius?.radius:'' });
        // }
        const data = props.lat
            ? { data: { ...props.query, lat: props.lat, long: props.lng, limit: 10, page: 1, hide_hiddens: true } }
            : { data: { ...props.query, limit: 10, page: 1, hide_hiddens: true } }
        dispatch(posts_array_saga(data))

    }, [])
    useEffect(() => {
        if (languages.results && languages.results.length > 0) {
            dispatch(get_posts_slider({
                language: languages.results.filter((el) => el.code === props.language.toUpperCase())[0].id,
                web_top:'True',
            }))
        }
    }, [languages.results])
    useEffect(() => {
        if (!props.lat) {
            getPosition();
        }
        if (props.loggedIn) return; // do nothing if the user is logged in
        router.push("/login");
    }, [props.loggedIn]);
    return {
        posts,
        popular_master,
        translate,
        mybutton,
        user,
        posts_slider,
        router,
        get_new_post,
        posts_loader,
        set_post_comment,
        add_child_comment,
        set_child_comment,
        add_comment,
        get_comments,
        hide_post,
        report_post,
        infiniteScroll,
        setMybutton,
    };
};
export default HomeHooks;