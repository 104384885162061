import router from 'next/router';
import category from '../services/api/service';

const getPosition = (cb) => {

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => showPosition(position, cb),
            posError,
            { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true });
    } else {
        alert("Sorry");
    }
};
export const getLatLong = async (address, cb) => {
    if (address) {
        let geocoder = await category.get_city_lat_lng({ search: address });
        if (geocoder.results.length > 0) {
            const city_position = { lat: geocoder.results[0].latitude, lng: geocoder.results[0].logitude }
            cb(city_position);
        } else {
            // router.push('/404');
        }
        // geocoder.geocode({ 'address': address }, function (results, status) {
        //     if (status == google.maps.GeocoderStatus.OK) {
        //         let city_position = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
        //         cb(city_position);
        //         return city_position;
        //     } else if (address) {
        //         router.push('/404');
        //     }
        // });
    }
};
const showPosition = (position, cb) => {
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    document.cookie = `lat=${JSON.stringify(lat)}; path=/`;
    document.cookie = `lng=${JSON.stringify(lng)}; path=/`;
    if (cb) {
        cb(lat, lng)
    }
    
};
const posError = (e) => {
    if (navigator.permissions.query({ name: 'geolocation' }).then(res => {
    })) {
        console.log('in posError');
    }
};
// export const getCity = (latitude, longitude, lg, cb) => {
//     var language = lg;
//     if (latitude && longitude) {
//         var url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude + "," + longitude + "&sensor=true&language=" + language + '&key=' + 'AIzaSyAJvkDZSorzrFzuPZ2byD-gWL0VN7q-jy0';
//         fetch(url)
//             .then((res) => res.json())
//             .then((json) => {
//                 let name = json.plus_code?.compound_code;
//                 if (name) {
//                     let name_list = name.split(' ');
//                     name_list.shift();
//                     const editedText = name_list[0].slice(0, -1);
//                     // name_list.join("")
//                     cb(editedText);
//                 }

//             });
//     }

// };

export default getPosition;
